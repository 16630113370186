const SpanishContent = {
  navbar: {
    ctaButton: "Contacta"
  },
  cardTitle: "Servicios",
  cards : [
    {
      id : "digitalMarketing",
      url: "/offers/digital-marketing",
      imgUrl: "/images/cards/digitalMarketingCard.jpg",
      title: "Marketing Digital",
      text: "Nuestra agencia está especializada en ofrecer servicios integrales, relacionados con el posicionamiento SEO en buscadores, hacer que tu web sea visible y esté bien posicionada en los motores de búsqueda, a través de SEO, Keywords o Backlinks. Contamos con un equipo propio para realizar la mejor estrategia SEO, SEM y Social Media."
    },
    {
      id: "softwareEngineering",
      url : "/offers/software-development",
      imgUrl : "/images/cards/softwareEngineeringCard.jpg",
      title : "Desarrollo de Software",
      text: "Nuestro servicio de mantenimiento y desarrollo de software a medida. Es un servicio de alta exigencia, calidad técnica y conocimiento muy necesario en los momentos que se viven."
    },
    {
      id: "automation",
      url : "/offers/automation",
      imgUrl : "/images/cards/automationCard.jpg",
      title : "Automatización de procesos",
      text: "La automatización de procesos es la operacionalización de un proceso que antes se ejecutaba manualmente, utilizando la tecnología y la integración de sistemas y datos. Esto es un paso importante en la gestión empresarial, capaz de generar competitividad, eficiencia y valor al cliente."
    },
    {
      id: "data protection",
      url : "/offers/data-protection",
      imgUrl : "/images/cards/dataProtectionCard.jpg",
      title : "Protección de datos",
      text: "Adáptate a la ley de protección de datos, RGPD y LOPD, al mejor precio y evita sanciones. Cumple la ley de protección de datos y evita multas al mejor precio con estas ofertas. Ideal para autónomos. Evita importantes multas."
    }
  ],
  homeForm: {
    title: "¿Quieres tener una reunión con el equipo?",
    imageUrl: "/images/forms/contactUs.jpg",
    ctaButtonText: "Enviar",
    ctaPath: "/confirmation"
  },
  inputContent : {
    nameLastNameInputPlaceholder: "Nombre y apellido",
    emailInputPlaceholder: "Email",
    phoneInputPlaceholder: "Teléfono",
    messageInputPlaceholder: "Mensaje",
  },
  contactPage: {
    imgUrl: "/images/forms/new-york.jpg",
    ctaButtonText: "Enviar",
    ctaPath: "/confirmation",
    contactParagraphTitle: "Confía en los mejores servicios de Marketing digital, Desarrollo y Automatización de procesos",
    contactParagraphText: "En ShineIT somos expertos en optimizar todos los canales en una estrategia común para lograr conversiones con el menor número de impactos y clicks. Nos encargamos de captar LEADs mediante SEO SEM, redes sociales o email marketing, mejorando la usabilidad y la conversión. Además de utilizar el diseño experimental para mejorar las ventas de nuestros productos y un alineamiento perfecto entre reconocimiento de marca, innovación y negocio.",
  },
  digitalMarketingOfferPage: {
    title: "¿Quieres tener una reunión con el equipo de marketing digital?",
    imageUrl: "/images/offers/digitalMarketingOffer.jpg",
    ctaButtonText: "Envía tu solicitud",
    ctaPath: "/confirmation",
  },
  softwareDevelopmentOfferPage: {
    title: "¿Quieres tener una reunión con el equipo de desarrollo de software?",
    imageUrl: "/images/offers/softwareDevelopmentOffer.jpg",
    ctaButtonText: "Envía tu solicitud",
    ctaPath: "/confirmation",
  },
  automationOfferPage: {
    title: "¿Quieres tener una reunión con el equipo de automatización de procesos?",
    imageUrl: "/images/offers/automationOffer.jpg",
    ctaButtonText: "Envía tu solicitud",
    ctaPath: "/confirmation",
  },
  dataProtectionOfferPage: {
    title: "¿Quieres tener una reunión con el equipo de protección de datos?",
    imageUrl: "/images/offers/dataProtectionOffer.jpg",
    ctaButtonText: "Envía tu solicitud",
    ctaPath: "/confirmation",
  },
  howItWorksPage: {
    title: "Cómo funciona",
    firstParagraph: "Hay diferentes problemas habituales referentes al marketing el cual se pueden encontrar en las empresas.",
    companyIssues: [
        {
          text: "No saber explicar el producto"
        },
        {
          text: "No encontrar tu segmento de mercado"
        },
        {
          text: "Poca visibilidad del negocio"
        },
        {
          text: "No tener un plan de marketing"
        },
        {
          text: "Poco reconocimiento de Marca"
        },
        {
          text: "Creer que no se necesita Marketing"
        }
      ],
    secondParagraph: "Nuestra misión es solucionar todos estos problemas en nuestras empresas con el use metódico del diseño experimental basado en marketing y desarrollo"
  },
  teamPage: {
    teamCardTitle: "Equipo",
    teamCards : [
      {
        id : "silvia",
        imgUrl: "/images/team/silviaPic.png",
        name: "Silvia Gálvez",
        role: "Marketing expert",
        description: "Silvia está especializada en ofrecer servicios integrales, relacionados con el posicionamiento SEO en buscadores, hacer que tu web sea visible y esté bien posicionada en los motores de búsqueda, a través de SEO, Keywords o Backlinks. Además experta en diseño para realizar la mejor estrategia SEO, SEM y Social Media."
      },
      {
        id : "joan",
        imgUrl: "/images/team/joanPic.jpg",
        name: "Joan Gómez Álvarez",
        role: "Senior Software Engineer",
        description: "Joan es un experto en tecnología y desarrollo de Software. Alto conocimiento en Frontend, Backend, automatización de procesos y en seguridad informática."
      },
    ],
  },
  supportPage: {
    supportCardTitle: "Soporte",
    supportCards : [
      {
        id : "support",
        imgUrl: "/images/support/contactUsSupport.jpg",
        name: "Contáctanos",
        role: "+34649313833",
        description: "Contacta para solucionar tus problemas."
      }
    ],
  },
  confirmationPage: {
    confirmationCardTitle: "¡Muchas gracias!",
    confirmationCards : [
      {
        id : "confirmation",
        imgUrl: "/images/confirmation/lyanna.png",
        name: "¡Tu solicitud ha sido enviada correctamente!",
        role: "Nos pondremos en contacto pronto",
        description: "Estamos encantados y con ganas de empezar a trabajar con vosotros :)"
      }
    ],
  },
  footer: {
    heading: "Únete a nuestra comunidad",
    subheading: "Recibe contenido de valor sobre Marketing y tecnología",
    nameInputPlaceholder: "Tu nombre",
    emailInputPlaceholder: "Tu email",
    ctaSubscribe: "Subscríbete",
    aboutUsTitle: "Sobre nosotros",
    aboutUsLinks: [
      {
        text : "Cómo funciona",
        url : "/how-it-works"
      },
      {
        text : "Equipo",
        url : "/team"
      },
    ],
    contactUsTitle:"Contáctanos",
    contactUsLinks: [
      {
        text : "Contacto",
        url : "/contact"
      },
      {
        text : "Soporte",
        url : "/support"
      },
    ],
  }
}

export default SpanishContent;