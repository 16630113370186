const EnglishContent = {
  cards : [
    {
      id : "digitalMarketing",
      url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Ftheconversation.com%2Fhow-ai-is-shaping-the-cybersecurity-arms-race-167017&psig=AOvVaw2JpIDIHryKUye2NQ-c9ahp&ust=1649709153852000&source=images&cd=vfe&ved=0CAoQjRxqFwoTCPjz1NOrivcCFQAAAAAdAAAAABAD",
      imgUrl: "/images/cards/digitalMarketingCard.jpg",
      title: "Digital Marketing",
      text: "Artificial Intelligence"
    },
    {
      id: "softwareEngineering",
      url : "https://www.google.com/url?sa=i&url=https%3A%2F%2Ftheconversation.com%2Fhow-ai-is-shaping-the-cybersecurity-arms-race-167017&psig=AOvVaw2JpIDIHryKUye2NQ-c9ahp&ust=1649709153852000&source=images&cd=vfe&ved=0CAoQjRxqFwoTCPjz1NOrivcCFQAAAAAdAAAAABAT",
      imgUrl : "/images/cards/softwareEngineeringCard.jpg",
      title : "Artificial Intelligence",
      text: "I am the idol"
    },
    {
      id: "automation",
      url : "https://www.google.com/url?sa=i&url=https%3A%2F%2Fsemicolon-ltd.com%2Fblog%2Farticle%2Fevolution-of-ai%3Flang%3Den&psig=AOvVaw2JpIDIHryKUye2NQ-c9ahp&ust=1649709153852000&source=images&cd=vfe&ved=0CAoQjRxqFwoTCPjz1NOrivcCFQAAAAAdAAAAABAO",
      imgUrl : "/images/cards/automationCard.jpg",
      title : "Hacker news",
      text: "I am the idol"
    },
    {
      id: "data protection",
      url : "https://www.google.com/url?sa=i&url=https%3A%2F%2Fsemicolon-ltd.com%2Fblog%2Farticle%2Fevolution-of-ai%3Flang%3Den&psig=AOvVaw2JpIDIHryKUye2NQ-c9ahp&ust=1649709153852000&source=images&cd=vfe&ved=0CAoQjRxqFwoTCPjz1NOrivcCFQAAAAAdAAAAABAO",
      imgUrl : "/images/cards/dataProtectionCard.jpg",
      title : "Hacker news",
      text: "Fucking master"
    }
  ],
  footer: {
    heading: "Join our community",
    subheading: "Get a valuable content about MKT and Development",
    inputEmailPlaceholder: "Your Email",
    inputNamePlaceholder: "Your name",
    ctaSubscribe: "Subscribe",
    aboutUsTitle: "About Us",
    aboutUsLinks: [
      {
        text : "How it works",
        url : "/how-it-works"
      },
      {
        text : "Team",
        url : "/team"
      },
    ],
    contactUsTitle:"Contact Us",
    contactUsLinks: [
      {
        text : "Contact",
        url : "/contact"
      },
      {
        text : "Support",
        url : "/support"
      },
    ],
  }
}

export default EnglishContent;